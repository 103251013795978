import * as React from "react";
import Layout from "../components/layout";
import { lightParagraph, darkParagraph, button, paragraphContent } from "./index.module.css";

const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page" showTitle={false}>
      <section style={{padding: '0'}}>

        <section className={lightParagraph}>
          <div className={paragraphContent}>
            <h1>Consultații juridice online</h1>
            <p>Pe lângă consultațiile juridice pe care le oferim la cabinetul nostru, oferim posibilitatea programării unor consultații juridice online, pe platforma Zoom. Știm că nimic nu se compară cu interacțiunea umană, însă considerăm necesar, în contextul mobilității tot mai crescute a populației, să ținem pasul cu evoluția tehnologiei și să ne adaptăm nevoilor clienților noștri. </p>
            <a className={button} href="/contact">Sună acum</a>
          </div>
        </section>
        <section className={darkParagraph}>
          <div className={paragraphContent} style={{ marginLeft: "auto" }}>
            <h2>Litigii</h2>
            <p>Asistența şi reprezentarea juridică în fața instanțelor judecătorești este partea dinamică și creativă a avocaturii, reprezentând o continuă provocare, prin necesitatea construirii unor strategii individuale, prin abordarea multidisciplinară a unor spețe în colaborare cu alți specialiști și prin conștientizarea importanței fiecărui detaliu. </p>
          </div>
        </section>
        <section className={lightParagraph}>
          <div className={paragraphContent}>
            <h2>Cunoaște-ți drepturile #EducațieJuridică</h2>
            <p>Cunoașterea drepturilor și obligațiilor pe care le aveți vă conferă posibilitatea să încălcați cât mai puține dintre obligațiile care vă incumbă și să solicitați mai des respectarea drepturilor pe care le aveți. Mărturisim că ne dorim clienți educați din punct de vedere juridic, care au o reprezentare clară a raporturilor juridice în care se află, motiv pentru care am pornit o campanie de educație juridică, prin care ne dorim să vă facem accesibile și inteligibile anumite noțiuni elementare de drept, aplicabile în situații juridice des întâlnite, și să vă ajutăm să vă cunoașteți drepturile și obligațiile.</p>
          </div>
        </section>
        <section className={darkParagraph}>
          <div className={paragraphContent} style={{ marginLeft: "auto" }}>
            <h2>Asistarea și reprezentarea în diferite proceduri ne-litigioase
</h2>
            <p>....</p>
          </div>
        </section>
        {/*<StaticImage*/}
        {/*  alt="logo image"*/}
        {/*  src="../images/logo.svg"*/}
        {/*/>*/}
      </section>
    </Layout>
  );
};

export default IndexPage;
